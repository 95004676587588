import { __decorate } from "tslib";
import { core } from "@/store/modules/core";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Line as LineChart } from "vue-chartjs";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AppRefNamer from "./shared/AppRefNamer";
let AppLineChart = class AppLineChart extends mixins(AppRefNamer) {
    constructor() {
        super(...arguments);
        this.colors = [
            "#4a148c",
            "#006064",
            "#1b5e20",
            "#880e4f",
            "#1a237e",
            "#0d47a1",
            "#ff6f00",
            "#e65100",
        ];
        this.chartOptions = {
            responsive: true,
            aspectRatio: 3,
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
        };
    }
    get chartName() {
        return this.getChartName(this.indicatorChart.chartId);
    }
    get indicatorChartReference() {
        return indicatorChartReferencesModule.indicatorChartReferences?.find((i) => i.id === this.indicatorChart.chartId);
    }
    get group() {
        return unitReferencesModule.unitReferences?.find((u) => u.id === this.indicatorChart.unitId)?.group;
    }
    get chartData() {
        const labels = [core.year - 3, core.year - 2, core.year - 1, core.year];
        const entries = this.indicatorChartReference.labels?.map((l) => ({
            labelId: l.id,
            values: [
                this.indicatorChart.n3?.find((e) => e.labelId === l.id)?.value ?? null,
                this.indicatorChart.n2?.find((e) => e.labelId === l.id)?.value ?? null,
                this.indicatorChart.n1?.find((e) => e.labelId === l.id)?.value ?? null,
                this.indicatorChart.entries?.find((e) => e.labelId === l.id)?.value ??
                    null,
            ],
        }));
        return {
            labels,
            datasets: entries.map((e, i) => {
                return {
                    label: this.getLabelName(this.indicatorChart.chartId, e.labelId),
                    data: e.values,
                    backgroundColor: this.colors[i],
                    borderColor: this.colors[i],
                };
            }),
        };
    }
};
__decorate([
    Prop()
], AppLineChart.prototype, "indicatorChart", void 0);
AppLineChart = __decorate([
    Component({
        components: { LineChart },
    })
], AppLineChart);
export default AppLineChart;
