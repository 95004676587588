import { __decorate } from "tslib";
import { v4 } from "uuid";
import { Bar } from "vue-chartjs";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AppRefNamer from "./shared/AppRefNamer";
let AppMultipleBarChart = class AppMultipleBarChart extends mixins(AppRefNamer) {
    constructor() {
        super(...arguments);
        this.id = v4();
        this.chartOptions = {
            responsive: true,
            aspectRatio: 3,
            indexAxis: "y",
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
        };
    }
    get chartName() {
        return `${this.units?.[0]?.year}`;
        // return `${this.units?.[0]?.year} - ${this.getChartName(this.chartId)}`;
    }
    get chartData() {
        return {
            labels: this.units?.map((u) => u.name),
            datasets: [
                {
                    data: this.units?.map((u) => u.total),
                    backgroundColor: this.color,
                },
            ],
        };
    }
};
__decorate([
    Prop()
], AppMultipleBarChart.prototype, "units", void 0);
__decorate([
    Prop()
], AppMultipleBarChart.prototype, "color", void 0);
__decorate([
    Prop()
], AppMultipleBarChart.prototype, "chartId", void 0);
AppMultipleBarChart = __decorate([
    Component({
        components: { Bar },
    })
], AppMultipleBarChart);
export default AppMultipleBarChart;
