import { __decorate } from "tslib";
import { Tabs } from "@materializecss/materialize";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let AppTabs = class AppTabs extends Vue {
    constructor() {
        super(...arguments);
        this.tabInstance = null;
    }
    get slotsArr() {
        return this.tabs && new Array(Number(this.tabs));
    }
    get currentTab() {
        return this.tabInstance?.index;
    }
    mounted() {
        setImmediate(() => this.initTabs());
    }
    updated() {
        setImmediate(() => this.tabInstance?.updateTabIndicator());
    }
    beforeDestroy() {
        this.tabInstance?.destroy();
    }
    initTabs() {
        if (!this.tabs)
            return;
        this.tabInstance?.destroy();
        const tabs = this.$refs.tabs;
        if (!tabs)
            return;
        this.tabInstance = Tabs.init(this.$refs.tabs, {
            swipeable: false,
        });
        this.select(this.value);
    }
    select(tabIndex) {
        this.tabInstance?.select(this.getTabId(tabIndex));
    }
    selectNextTab() {
        this.select(this.currentTab);
    }
    getSlotId(tabIndex) {
        return "tab" + tabIndex;
    }
    getTabId(tabIndex) {
        return this.id + "-tab-" + tabIndex;
    }
    onTabsChanged() {
        setImmediate(() => this.initTabs());
    }
    onValueChanged(value, previousValue) {
        if (value !== previousValue)
            this.select(value);
    }
    onCurrentTabChanged(currentTab, previousTab) {
        if (currentTab !== previousTab)
            this.$emit("input", currentTab);
    }
};
__decorate([
    Prop({ default: "app-tabs" })
], AppTabs.prototype, "id", void 0);
__decorate([
    Prop({ default: 0 })
], AppTabs.prototype, "tabs", void 0);
__decorate([
    Prop({ default: 0 })
], AppTabs.prototype, "value", void 0);
__decorate([
    Prop({ default: () => [] })
], AppTabs.prototype, "hiddenTabs", void 0);
__decorate([
    Watch("tabs")
], AppTabs.prototype, "onTabsChanged", null);
__decorate([
    Watch("value")
], AppTabs.prototype, "onValueChanged", null);
__decorate([
    Watch("currentTab")
], AppTabs.prototype, "onCurrentTabChanged", null);
AppTabs = __decorate([
    Component
], AppTabs);
export default AppTabs;
