import { __decorate } from "tslib";
import indicatorChartsAPI from "@/api/indicatorCharts";
import { core } from "@/store/modules/core";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import AppBarChart from "./AppBarChart.vue";
import AppLineChart from "./AppLineChart.vue";
import AppMultipleBarChart from "./AppMultipleBarChart.vue";
import OverviewMap from "./OverviewMap.vue";
import AppRefNamer from "./shared/AppRefNamer";
import AppBtn from "./shared/ui/AppBtn.vue";
import AppModal from "./shared/ui/AppModal.vue";
import AppSelect from "./shared/ui/AppSelect.vue";
import AppTabs from "./shared/ui/AppTabs.vue";
import AppTextPlaceholder from "./shared/ui/AppTextPlaceholder.vue";
let Overview = class Overview extends mixins(AppRefNamer) {
    constructor() {
        super(...arguments);
        this.indicatorCharts = null;
        this.n1IndicatorCharts = null;
        this.n2IndicatorCharts = null;
        this.n3IndicatorCharts = null;
        this.showSelectedCompanyModal = false;
        this.selectedCompany = null;
        this.activeTab = 0;
    }
    get shameUnits() {
        const chardIds = this.indicatorCharts?.reduce((chartIds, curr) => {
            if (!chartIds.includes(curr.chartId))
                chartIds.push(curr.chartId);
            return chartIds;
        }, []);
        return chardIds?.map((chartId) => ({
            chartId,
            n: this.getShameUnits(this.indicatorCharts, core.year, chartId),
            n1: this.getShameUnits(this.n1IndicatorCharts, core.year - 1, chartId),
        }));
    }
    get overviewUnits() {
        if (!this.indicatorCharts?.length ||
            !this.shameUnits?.length ||
            !indicatorChartReferencesModule?.indicatorChartReferences?.length)
            return [];
        const chartId = this.shameUnits[this.activeTab].chartId;
        const fireIndicatorCharts = this.getFireGroupIndicators(this.indicatorCharts)?.filter((i) => i.chartId === chartId);
        if (!fireIndicatorCharts?.length)
            return [];
        const overviewUnits = fireIndicatorCharts.map((indicatorChart) => {
            return {
                value: this.getIndicatorTotal(indicatorChart, true),
                color: "green",
                unitId: indicatorChart.unitId,
            };
        });
        overviewUnits.sort((a, b) => a.value - b.value);
        // trier par quartiles
        const limits = overviewUnits.map((u) => u.value);
        const min = Math.min(...limits);
        const max = Math.max(...limits);
        const step = (max - min) / 4;
        const colors = ["green", "yellow", "orange", "red"];
        overviewUnits.forEach((unit) => {
            let i = 0;
            while (unit.value > min + (i + 1) * step) {
                i++;
            }
            unit.color = colors[i];
        });
        return overviewUnits;
        // 4 groupes de la meme taille
        // const chunks = chunkify(overviewUnits, colors.length, true);
        // console.log("chunck", chunks);
        // for (let i = 0; i < chunks.length; i++) {
        //   const color = colors[i];
        //   for (const overviewUnit of chunks[i]) {
        //     overviewUnit.color = color;
        //   }
        // }
        // return chunks.flat();
    }
    get overviewGroups() {
        return this.groups
            ?.filter((g) => (this.group == "BSPP" ? true : g === this.group))
            .sort();
    }
    get loadChartParams() {
        return core.group && core.year && core.indicatorId && new Date();
    }
    get indicator() {
        return this.getIndicatorName(core.indicatorId);
    }
    get group() {
        return core.group;
    }
    set group(group) {
        core.setGroup(group);
    }
    get groupItems() {
        return (this.groups &&
            [{ label: "BSPP", value: "BSPP" }].concat(this.groups
                .map((group) => ({ label: group, value: group }))
                .sort((a, b) => a.label.localeCompare(b.label))));
    }
    get groups() {
        return unitReferencesModule.unitReferences?.reduce((groups, unit) => {
            if (!groups.includes(unit.group))
                groups.push(unit.group);
            return groups;
        }, []);
    }
    get notAll() {
        return this.group && this.group !== "BSPP";
    }
    mounted() {
        this.$events.$emit("menu-change", {
            menu: "Indicateurs",
            subMenu: "Vue d'ensemble",
        });
    }
    async onLoadChartParamsChange() {
        this.activeTab = 0;
        this.indicatorCharts = await indicatorChartsAPI.loadGroup(core.year, core.indicatorId, core.group);
        this.n1IndicatorCharts = await indicatorChartsAPI.loadGroup(core.year - 1, core.indicatorId, core.group);
        this.n2IndicatorCharts = await indicatorChartsAPI.loadGroup(core.year - 2, core.indicatorId, core.group);
        this.n3IndicatorCharts = await indicatorChartsAPI.loadGroup(core.year - 3, core.indicatorId, core.group);
    }
    getGroupCompanies(group) {
        return unitReferencesModule.unitReferences?.reduce((companies, unit) => {
            if (unit.group === group && !companies.includes(unit.company))
                companies.push(unit.company);
            return companies;
        }, []);
    }
    getGroupIndicators(group, indicatorCharts = this.indicatorCharts) {
        const unitIds = unitReferencesModule.unitReferences
            ?.filter((u) => group === "BSPP" || u.group === group)
            ?.map((u) => u.id);
        return (unitIds && indicatorCharts?.filter((i) => unitIds.includes(i.unitId)));
    }
    getCompanyIndicators(company, indicatorCharts = this.indicatorCharts) {
        const unitIds = unitReferencesModule.unitReferences
            ?.filter((u) => u.company === company)
            ?.map((u) => u.id);
        return (unitIds && indicatorCharts?.filter((i) => unitIds.includes(i.unitId)));
    }
    getAggregatedEvolutionIndicatorCharts(name, type) {
        const indicators = type === "group"
            ? this.getGroupIndicators(name)
            : this.getCompanyIndicators(name);
        const n1 = type === "group"
            ? this.getGroupIndicators(name, this.n1IndicatorCharts)
            : this.getCompanyIndicators(name, this.n1IndicatorCharts);
        const n2 = type === "group"
            ? this.getGroupIndicators(name, this.n2IndicatorCharts)
            : this.getCompanyIndicators(name, this.n2IndicatorCharts);
        const n3 = type === "group"
            ? this.getGroupIndicators(name, this.n3IndicatorCharts)
            : this.getCompanyIndicators(name, this.n3IndicatorCharts);
        const refs = indicatorChartReferencesModule.indicatorChartReferences?.filter((i) => indicators?.some((ii) => ii.chartId === i.id));
        const aggregatedIndicators = refs?.map((ref) => {
            const getEntries = (iis) => ref?.labels?.map((label) => ({
                labelId: label.id,
                value: iis?.reduce((total, indicator) => {
                    const value = indicator.entries?.find((e) => e.labelId === label.id)?.value ??
                        0;
                    total += value;
                    return total;
                }, 0),
            }));
            return {
                id: ref.id,
                chartId: ref.id,
                year: core.year,
                unitId: name,
                entries: getEntries(indicators),
                n1: getEntries(n1),
                n2: getEntries(n2),
                n3: getEntries(n3),
                indicatorId: ref.indicatorId,
            };
        });
        return aggregatedIndicators ?? [];
    }
    showSelectedCompany(company) {
        this.selectedCompany = company;
        this.showSelectedCompanyModal = true;
    }
    setGroup(group) {
        core.setGroup(group);
        window.scrollTo(0, 0);
    }
    getShameUnitSlotTitleName(index) {
        return `tab${index}-title`;
    }
    getShameUnitSlotContentName(index) {
        return `tab${index}-content`;
    }
    getIndicatorTotal(indicatorChart, useGroupAverageAsTotal) {
        const indicatorChartReference = indicatorChartReferencesModule.indicatorChartReferences?.find((i) => i.id === indicatorChart.chartId);
        if (useGroupAverageAsTotal &&
            indicatorChartReference?.showGroupAverage &&
            indicatorChartReference?.showGroupAverage != "total")
            return this.getIndicatorSelectedAverageValue(indicatorChart, indicatorChartReference.showGroupAverage);
        const entries = indicatorChartReference?.labels?.map((l) => ({
            labelId: l.id,
            value: indicatorChart.entries?.find((e) => e.labelId === l.id)?.value ??
                null,
        }));
        return entries?.reduce((acc, curr) => acc + curr.value, 0) ?? 0;
    }
    getIndicatorSelectedAverageValue(indicatorChart, groupAverageLabelId) {
        return (indicatorChart.entries?.find((e) => e.labelId === groupAverageLabelId)
            ?.value ?? 0);
    }
    getFireGroupIndicators(indicatorCharts) {
        return indicatorCharts?.filter((i) => {
            const unit = unitReferencesModule.unitReferences?.find((u) => u.id === i.unitId);
            return ["GIS1", "GIS2", "GIS3"].includes(unit.group);
        });
    }
    getShameUnits(indicatorCharts, year, chartId) {
        if (!indicatorCharts?.length)
            return null;
        const shameUnits = [];
        for (const indicatorChart of indicatorCharts) {
            if (indicatorChart.chartId === chartId) {
                const total = this.getIndicatorTotal(indicatorChart, true);
                const name = this.getUnitName(indicatorChart.unitId);
                shameUnits.push({ total, name, unitId: indicatorChart.unitId, year });
            }
        }
        shameUnits.sort((a, b) => b.total - a.total);
        return shameUnits.slice(0, 9);
    }
};
__decorate([
    Watch("loadChartParams", { immediate: true })
], Overview.prototype, "onLoadChartParamsChange", null);
Overview = __decorate([
    Component({
        components: {
            AppBtn,
            AppTextPlaceholder,
            AppBarChart,
            AppLineChart,
            OverviewMap,
            AppSelect,
            AppMultipleBarChart,
            AppModal,
            AppTabs,
        },
    })
], Overview);
export default Overview;
