import { __decorate } from "tslib";
import { core } from "@/store/modules/core";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppBarChart from "./AppBarChart.vue";
import AppBtn from "./shared/ui/AppBtn.vue";
import AppTextPlaceholder from "./shared/ui/AppTextPlaceholder.vue";
let OverviewMap = class OverviewMap extends Vue {
    get visibleGroups() {
        return unitReferencesModule.unitReferences?.reduce((groups, unitReference) => {
            if (this.units?.some((u) => u.unitId === unitReference.id) &&
                !groups.includes(unitReference.group))
                groups.push(unitReference.group);
            return groups;
        }, []);
    }
    get visibleCompanies() {
        return unitReferencesModule.unitReferences?.reduce((companies, unitReference) => {
            if (this.units?.some((u) => u.unitId === unitReference.id) &&
                !companies.includes(unitReference.company))
                companies.push(unitReference.company);
            return companies;
        }, []);
    }
    isGroupActive(group) {
        return core.group === "BSPP" || core.group === group;
    }
    isCompanyVisible(company) {
        return true || this.visibleCompanies?.includes(company);
    }
    isUnitVisible(unitId) {
        return true || this.units?.some((u) => u.unitId === unitId);
    }
    getFillColor(unitId) {
        return this.units?.find((u) => u.unitId === unitId)?.color ?? "#ffffff";
    }
};
__decorate([
    Prop()
], OverviewMap.prototype, "units", void 0);
OverviewMap = __decorate([
    Component({
        components: {
            AppBtn,
            AppTextPlaceholder,
            AppBarChart,
        },
    })
], OverviewMap);
export default OverviewMap;
